<template>
    <div class="Salelist">
        <!-- <iframe id="geoPage" width=100% height=100% frameborder=0  style="display:none;" scrolling="no"
            :src="mapUrl">
        </iframe> -->
        <div class="Location-haed">
            <i class="iconfont icondingwei"></i>
            <span class="my-address" >{{address_name}}</span>
            <i class="iconfont iconxiangyoujiantou"></i>
            <span class="restart" @click="getlocations(1)" >重新定位</span>
        </div>
        <div class="Location-list" >
            <div v-for="(item,index) in list" :key="index">
            <div class="list" @click="setHied(item)">
                <div class="images-box" v-if="displayList == item">
                    <div class="images-box-in">
                        <img :src="item.image" />
                    </div>
                </div>
                <div class="fixd fixd-left">
                    <div class="store_name font-spacing">{{item.name}}</div>
                    <div class="store_address font-spacing Ellipses1"><i class="iconfont icondingwei "></i>{{item.detailed_address}}</div>
                    <div class="store_address font-spacing "><i class="iconfont iconliwu "></i>经营范围：{{item.beizhu}}</div>
                    <div class="store_address font-spacing "><i class="iconfont icondaohang"></i>{{item.distance}}</div>
                    <!-- <div class="time font-spacing"><i class="iconfont iconshijian00"></i>{{item.day_time}}</div> -->
                </div>
                <div class="bit" v-if="displayList == item">
                    <div @click.stop="openMap(item.latitude,item.longitude,item.name,item.detailed_address)">查看地图</div>
                </div>
            </div>
            </div>
                <div class="Loading" v-if="loading">
                <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="finished">{{msg}}</div>
        </div>
        
    </div>

</template>
<script>
    import wx from "weixin-js-sdk";
    import { wechatEvevt,getLocation,isIOS,requestWxStr,toAuth} from "@libs/wechat";
    import { isWeixin,isWeixin_temp } from "@utils/index";
    import { getSaleDianList,getLocationRegin } from "@api/tasting";
    import { Toast } from "vant";
export default {
    name:'Salelist',
    components:{
        Toast
    },
    data:function(){
        return{
            list:[],
            displayList:{},
            mapUrl:'',
            loading:false,
            finished:false,
            page:1,
            limit:8,
            msg:'没有更多了~',
            lat : this.$store.state.app.latitude,
            lng : this.$store.state.app.longitude,
            address_name :'请定位',
            hiedStatus:false,
        }
    },
    mounted(){
        this.getlocations();
        //this.Loadmore();
         //腾讯地图获取定位
        var that = this;
        // window.addEventListener('message', function(event) {
        //     //Toast.success(event.data.lat);
        //     if(!that.$store.state.app.latitude&&!that.$store.state.app.longitude){
        //         if(!isWeixin_temp()){
        //             // 接收位置信息
        //             var res = event.data;
        //             if(res.lat&&res.lng){
        //                 that.$store.state.app.latitude = res.lat;// 纬度，浮点数，范围为90 ~ -90
        //                 that.$store.state.app.longitude = res.lng;//经度，浮点数，范围为180 ~ -180。
        //             }
        //             that.list = [];
        //             that.getList();
        //         }
        //     }
        // }, false);
        window.addEventListener("scroll", function(event) {
            var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop ) {
                that.Loadmore();
            }
        });
    },
    methods:{
        //初始化 点击显示按钮图片
        setHiedInit(){
            if(!this.list)return;
            if(this.hiedStatus)return;
             this.displayList = this.list[0];
             this.hiedStatus = true;
        },
        //点击显示按钮图片
        setHied(arr){
            this.displayList = arr;
        },
        Loadmore: function() {
            if(this.loading) return;
            if(this.finished) return;
            this.getList();
        },
        //列表
        getList(){
            this.msg = '没有更多了~';
            this.loading = true;
            getSaleDianList({
                mylat:this.lat,
                mylng:this.lng,
                page:this.page,
                limit:this.limit
            }).then(res=>{
                var listdata = res.data.list;
                listdata.forEach((item)=>{
                    this.list.push(item);
                })
                this.loading = false;
                this.page++;
                if(listdata.length<8){
                    this.finished = true;
                }
                if(res.data.code==0){
                    this.msg = res.data.msg;
                }
                this.setHiedInit();
            }).catch(err=>{
                Toast.clear();
                Toast.fail(err);
            });
        },
        getRegin(lat,lng,code){
            var that = this;
            var text = document.querySelector('.my-address');
            if(this.$store.state.app.address_name&&!code){
                this.setReset();
                text.innerHTML=this.$store.state.app.addressName;
                that.Loadmore();
                return
            }
            Toast.loading({
                    duration: 0,
                    message: '正在获取地址信息',
                    forbidClick: true,
            });
            setTimeout(function(){
                getLocationRegin({
                    lat:lat,
                    lng:lng,
                }).then(res=>{
                    Toast.clear();
                    that.setReset();
                    that.$store.state.app.addressName=res.msg;
                    text.innerHTML=res.msg;
                    that.Loadmore();
                }).catch(err=>{
                    Toast.clear();
                    Toast.fail('获取失败')
                });
            },3000);   
        },
        setReset:function(){
            this.list = [];
            this.loading=false;
            this.finished=false;
            this.page = 1;
        },
        //获取定位
        getlocations(code){
            this.hiedStatus = false;
            if((this.$store.state.app.latitude==undefined||this.$store.state.app.longitude==undefined)||code==1){
                if(isWeixin_temp()){
                        let config = {
                                type: 'gcj02'
                        };
                        getLocation().then(res => {
                            this.$store.state.app.latitude = res.latitude;// 纬度，浮点数，范围为90 ~ -90
                            this.$store.state.app.longitude = res.longitude;//经度，浮点数，范围为180 ~ -180。
                            this.lat = res.latitude;
                            this.lng = res.longitude;
                            this.getRegin(res.latitude,res.longitude,code);
                        }).catch(err => {
                            alert(err);
                            Toast.fail('错咯');
                            if (err.is_ready) {
                            err.wx.getLocation();
                        }
                    });
                }else{
                    Toast.fail('请到微信访问');
                    this.mapUrl='https://apis.map.qq.com/tools/geolocation?key=QQUBZ-OGSWP-6ESDL-LGQYP-OPCVQ-KVB67&referer=wcj';
                }
            }else{
                Toast.fail('出错啦001');
                this.getRegin(this.$store.state.app.latitude,this.$store.state.app.longitude,0);
            }
        },
        openMap(lat,lng,store_name,store_address){
                if(isWeixin_temp()){
                    let config = {
                        latitude: Number(lat), // 纬度，浮点数，范围为90 ~ -90
                        longitude: Number(lng), // 经度，浮点数，范围为180 ~ -180。
                        name: store_name, // 位置名
                        address: store_address, // 地址详情说明
                        scale: 13, // 地图缩放级别,整型值,范围从1~28。默认为最大
                        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
                    };
                    wechatEvevt("openLocation", config)
                    .then(res => {
                        Toast.success(res)
                    })
                    .catch(res => {
                        //res.wx.getLocation(config);
                        Toast.fail(res)
                    });
                }else{
                    Toast.fail('非微信环境');
                }
            },
    }
}
</script>
<style lang="scss">
    .Salelist{
        .Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
        color: #000;
        font-weight: 600;
        
        .Location-haed{
            position:fixed;
            z-index: 10;
            padding-left: 0.2rem;
            border-bottom:1px solid #eee;
            background-color: #fff;
            width: 100%;
            height: 50px;
            line-height: 50px;
            .icondingwei{
                margin-right:0.2rem ;
            }
            .iconxiangyoujiantou{
                font-size: 0.22rem;
                -webkit-font-size:0.1rem;
                margin-left: 0.2rem;
                font-weight: 800;
            }
            .restart{
                float: right;
                margin-right: 0.2rem;
                color: rgb(255, 188, 2);
            }
        }
        .Location-list{
            margin-top: 60px;
            
            .list{
                //display: flex;
                width: 84%;
                margin: 3% 8%;
                border-bottom: 1px solid #eee;
                //max-height: 150px;
                //min-height: 111px;
                //position: relative;
                color:rgb(95, 95, 95);
                .images-box{
                    height: 3.5rem;
                    width: 100%;
                    overflow: hidden;
                    .images-box-in{
                        //padding: 0.2rem;
                        height: 3.5rem;
                        position: relative;
                        img{
                            position:absolute;
                            //height: 100%;
                            width: 8rem;
                            margin-left:50%;
                            left: -4rem;
                        }
                    }
                }
                .fixd{
                    padding: 0.2rem 0;
                    font-size: 0.22rem;
                    font-weight: 200;
                    i{
                         font-size: 0.22rem;
                    }
                    .store_name{
                        font-weight: 600;
                        padding: 0.06rem;
                        color: #000;
                        font-size:0.25rem;
                    }
                    .font-spacing{
                        padding: 0.05rem 0;
                    }
                }
                .bit{
                    width: 100%;
                    margin-bottom: 0.1rem;
                    div{
                        text-align: center;
                        margin: 0 auto;
                        padding: 0.15rem 0;
                        background: red;
                        color: #fff;
                        //width: 80%;
                        border-radius: 0.1rem;
                        
                    }
                }
            }
        }
    }
</style>